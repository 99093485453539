import React, { useEffect, useState } from "react";
import styles from './admin-sorting-block.module.less';
import TournamentService from 'services/tournament-standings-service';

const size = 27;

const AdminSortingBlock = () => {

    const [tournamentsCategories, setTournamentsCategories] = useState([]);
    const [tournamentsCategoriesLength, setTournamentsCategoriesLength] = useState([]);
    const [subTournamentsCategories, setSubTournamentsCategories] = useState([]);

    const getAllTournamentsCategories = async() => {
        await TournamentService.getAllTournamentsCategories()
            .then(res => {
                setTournamentsCategories(res.data);
                setTournamentsCategoriesLength(Array.from(Array(res.data?.length).keys()));
                let subArray = [];
                for (let i = 0; i <Math.ceil(res.data?.length/size); i++){
                    let data = subArray.push(res.data?.slice((i*size), (i*size) + size));
                }
                setSubTournamentsCategories(subArray);
            }).catch(err => {
            })
    }

    useEffect(() => {
        getAllTournamentsCategories()
    },[]);

    const handleSelectChange = async(itemId, newValue) => {
        await TournamentService.saveTournamentCategoryOrder(itemId, newValue).then(res => {
        setTournamentsCategories(res.data);
        setTournamentsCategoriesLength(Array.from(Array(res.data?.length).keys()));
        let subArray = [];
                for (let i = 0; i <Math.ceil(res.data?.length/size); i++){
                    subArray.push(res.data?.slice((i*size), (i*size) + size));
                }
                setSubTournamentsCategories(subArray);
    }).catch(err => {
    });
  };


    return <div className={styles.mainBlock}>
        <h4>Sorting</h4>
        <div className={styles.tableMainBlock}>
        {subTournamentsCategories?.length > 0 && subTournamentsCategories.map(stc => 
            <table key={stc} className={styles.tableHeader}>
                <tr>
                    <th>League</th>
                    <th>Order</th>
                </tr>
                {stc.map(el => <tr key={el.id}>
                    <td>{el.displayTournamentCategoryName ?? el.name}</td>
                    <td>
                        <select
                            className={styles.selectOrder} 
                            defaultValue={el.tournamentCategoryOrder} 
                            value={el.tournamentCategoryOrder} 
                            onChange={e => handleSelectChange(el.id, e.target.value)}
                            name="order" 
                            id="order">                            
                            {tournamentsCategoriesLength.map(count => 
                            <option value={count + 1} key={count}>
                                {count + 1}
                            </option>)}
                        </select>
                    </td>
                </tr>)}

            </table>
        )
        }
        </div>
    </div>
}

export default AdminSortingBlock;